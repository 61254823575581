import axios from "axios";



const Axios = axios.create({
    baseURL: "http://192.168.1.5:8060/api/v1/",
    headers: {
        "Content-Type": "application/json",

        // "Authorization": `Bearer ${localStorage.getItem("token")}`
    },
    
})

export default Axios