


import Axios from "config/axios"


/// login 
export const Singup = async (data) => {
    try {
        const res = Axios.post('users/',data)
        return res
    } catch (error) {
        return error
    }
}



/// login 
export const Login = async (data) => {
    try {
        const res = Axios.post('users/',data)
        return res
    } catch (error) {
        return error
    }
}


